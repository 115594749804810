import request from '@/utils/request'


// 查询动态类目列表
export function listCategory(query) {
  return request({
    url: '/user/dynamic-categorys/list',
    method: 'get',
    params: query
  })
}

// 查询动态类目分页
export function pageCategory(query) {
  return request({
    url: '/user/dynamic-categorys/page',
    method: 'get',
    params: query
  })
}

// 查询动态类目详细
export function getCategory(data) {
  return request({
    url: '/user/dynamic-categorys/detail',
    method: 'get',
    params: data
  })
}

// 新增动态类目
export function addCategory(data) {
  return request({
    url: '/user/dynamic-categorys/add',
    method: 'post',
    data: data
  })
}

// 修改动态类目
export function updateCategory(data) {
  return request({
    url: '/user/dynamic-categorys/edit',
    method: 'post',
    data: data
  })
}

// 删除动态类目
export function delCategory(data) {
  return request({
    url: '/user/dynamic-categorys/delete',
    method: 'post',
    data: data
  })
}
export function changeIsOpen(data) {
  return request({
    url: '/user/dynamic-categorys/edit',
    method: 'post',
    data: data
  })
}
